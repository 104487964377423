import React, { useState } from 'react';
import Dropdown from './components/dropdown';
import TextArea from './components/balance';
import TextArea2 from './components/airdropinfo';
import { AppProvider } from './components/AppContext'; // Ensure correct path
import Navbar from './components/navbar';

import './App.css';

function App() {
  const [textValue, setTextValue] = useState<string>('');
  const [inputValues, setInputValues] = useState<number[]>([]);
  const [isApplyToAllChecked, setIsApplyToAllChecked] = useState(false); // Checkbox state
  const [applyValue, setApplyValue] = useState<number>(0); // State to store the value to apply to all lines

  const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTextValue(e.target.value);
  };
  const textLines = textValue.split('\n').filter(line => line.trim() !== '');
  // Function to handle input changes for number fields
  const handleInputChange = (index: number, value: string) => {
    const newInputValues = [...inputValues];
    newInputValues[index] = value ? parseFloat(value) : 0;
    setInputValues(newInputValues);
  };
  const formatAddress = (address: string): string => {
    // Check if address starts with "0x" and is long enough
    if (address.startsWith('0x') && address.length > 8) {
      return `${address.slice(0, 6)}...${address.slice(-4)}`;
    }
    return address; // Return as is if it's too short or doesn't start with "0x"
  };

  // Apply value to all lines
  const applyValueToAll = () => {
    if (isApplyToAllChecked) {
      const updatedValues = textLines.map(() => applyValue);
      setInputValues(updatedValues);
    }
  };
  // Calculate the sum of the input values
  const totalSum = inputValues.reduce((acc, curr) => acc + curr, 0);
  const addresses = textLines; // Array of addresses
  const values = inputValues;  // Array of input values
  return (
    <AppProvider>
    <div className="App">
    <Navbar />
    <div className="heading">
        Batch transfer tool
      </div>
      <div className="grid">
        <div className='item'>
          <Dropdown />
        </div>
        <div className='item'>
        <TextArea
          value={textValue}
          onChange={handleTextChange}
          placeholder="Enter addresses..."
        />
        </div>
        <div className='item2'>
        <div className='title'>Airdrop info</div>
        <div className="apply-all-container">
        <label>
          <input
            type="checkbox"
            checked={isApplyToAllChecked}
            onChange={(e) => setIsApplyToAllChecked(e.target.checked)}
            className="apply-all-checkbox"
          />
          Equal amount
        </label>
        <input
          type="number"
          value={applyValue}
          onChange={(e) => setApplyValue(parseFloat(e.target.value) || 0)}
          disabled={!isApplyToAllChecked} // Disable input if checkbox is unchecked
          className={`apply-all-input ${!isApplyToAllChecked ? 'disabled' : ''}`}
          placeholder="Enter number"
        />
        <button onClick={applyValueToAll} className="apply-button" disabled={!isApplyToAllChecked}>
          Apply
        </button>
        </div>
        {textLines.length > 0 && (
  <div className="line-list">
    {textLines.map((line, index) => (
      <div key={index} className="line-item">
        <span>
          {index + 1}. {formatAddress(line.trim())}
        </span>
        {/* Add a text input next to each line */}
        <input
          type="number"
          className="line-input"
          value={inputValues[index] || ''}
          onChange={(e) => handleInputChange(index, e.target.value)}
          placeholder="Enter number"
        />
      </div>
    ))}
  </div>
)}

        {textLines.length > 0 && (
          <div className="summary">
            <div>
              <p className='balance-label'>Summary</p>
              <p>Number of recepients: {textLines.length}</p>
              <p>Total sum: {totalSum.toFixed(2)}</p>
              <p>Your remaining balance: 420</p>
              <p>Total sum: {totalSum.toFixed(2)}</p>
            </div>
          </div>
        )}
        <div className='right-side'>
        <div className="button-container">
          <button className="summary-button">Approve</button>
          <button className="summary-button">Confirm tx</button>
        </div>
        </div>
        <div className="individual-arrays">
        <h3>Addresses:</h3>
          <pre>{JSON.stringify(addresses, null, 2)}</pre>
        <h3>Values:</h3>
          <pre>{JSON.stringify(values, null, 2)}</pre>
        </div>
        </div>
      </div>  
    </div>
    </AppProvider>

  );
}

export default App;

import React from 'react';
import '../componentstyles/textinput.css';
interface TextAreaProps {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  rows?: number; // Optional: Number of rows for the textarea
  onCopy?: () => void; // Optional: Callback for copy action
}

const TextArea: React.FC<TextAreaProps> = ({ value, onChange, placeholder, rows = 14, onCopy }) => {  const handleCopy = () => {
    navigator.clipboard.writeText(value).then(() => {
      if (onCopy) onCopy();
    }).catch(err => {
      console.error('Failed to copy text: ', err);
    });
  };

  return (
    <div>
    <div className='title'>Recepient list</div>
    <textarea
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        rows={rows}
        className="text-area" // Apply your CSS class here
      />
      <div className='button-container2'>
        <button onClick={handleCopy} className="copy-button">
          Copy recepient list
        </button>
      </div>
    </div>
  );
};

export default TextArea;

import React, { createContext, useContext, useState, ReactNode } from 'react';

interface AppState {
    idsOf: string[] | null;
    setIdsOf: React.Dispatch<React.SetStateAction<string[] | null>>;
    difference: string[] | null;
    setDifference: React.Dispatch<React.SetStateAction<string[] | null>>;
}

const AppContext = createContext<AppState | undefined>(undefined);

export const AppProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [idsOf, setIdsOf] = useState<string[] | null>(null);
  const [difference, setDifference] = useState<string[] | null>(null);

  return (
    <AppContext.Provider value={{ idsOf, setIdsOf, difference, setDifference }}>
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useAppContext must be used within an AppProvider');
  }
  return context;
};

import React, { useState, useEffect, useRef  } from 'react';
import '../componentstyles/navbar.css'; // Import your CSS file

interface NavbarProps {
    isDark: boolean;
    toggleTheme: () => void;
    onSelect: (section: 'minting' | 'staking') => void;
  }

const Navbar: React.FC = () => {
    const [isSticky, setIsSticky] = useState(false);
    const [sidebarVisible, setSidebarVisible] = useState(false);
    const sidebarRef = useRef<HTMLUListElement>(null);
    const [tokenPrice, setTokenPrice] = useState<number | null>(null);
  
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };
  
    const handleClickOutside = (event: MouseEvent) => {
        if (sidebarRef.current && !sidebarRef.current.contains(event.target as Node)) {
            setSidebarVisible(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
  const showSidebar = () => {
    setSidebarVisible(true);
  };

  const hideSidebar = () => {
    setSidebarVisible(false);
  };

  return (
    <header id="myHeader">
    <nav className={`navbar ${isSticky ? 'sticky' : ''}`}>
      <ul ref={sidebarRef} className={`sidebar ${sidebarVisible ? 'visible' : ''}`}> 
       <li onClick={hideSidebar}>
        <a href="#">
            <div className={`x ${sidebarVisible ? 'open' : ''}`}>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </a>
        </li>
        <li onClick={hideSidebar}><a href="https://www.dogetools.dog/">Home</a></li>
        <li><a href="https://stake.dogetools.dog/">Token Staking</a></li>
        <li>
                    <a className='Connect' onClick={hideSidebar}><w3m-button /></a>
                </li>
        </ul>
      <ul>
        <li><a href="#">DogeTools</a></li>
        <li>
</li>
        <li className="hideOnMobile"><a href="https://www.dogetools.dog/">Home</a></li>
        <li className="hideOnMobile"><a href="https://stake.dogetools.dog/">Token Staking</a></li>
        <li className="hideOnMobile"><a><w3m-button /></a></li>
        <li className="menu-button" onClick={showSidebar}>
          <a href="#">
            <div className={`hamburger ${sidebarVisible ? 'open' : ''}`}>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </a>
        </li>
      </ul>
    </nav>
    <div style= {{
      position: 'fixed',
                top: '70px',
                height: '30px',
    }}
    >
    {tokenPrice !== null && tokenPrice < 80 && (
        <p style={{
          textAlign: 'center',
          alignContent: 'center',
          background: 'var(--sale-color)',
          padding: '1rem',
          fontSize: '1.3rem',
          width: '100%',
          boxSizing: 'border-box',
          position: 'fixed',
          color: 'var(--sale-text)',
          marginBottom: '1rem',
          marginTop: '0'
        }}>
          NFT Sale - NFTs reduced by -{(1 - (tokenPrice / 80)) * 100}%
        </p>
      )}
    </div>
    </header>
  );
};

export default Navbar;

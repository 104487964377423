import React, { useState, useRef, useEffect } from 'react';
import '../componentstyles/dropdown.css';
import optionsData from '../options.json'; // Import your JSON data
import web3 from '../utils/web3';
import DToolsTokenAbi from '../abis/DToolsTokenAbi.json';
import { AbiItem } from 'web3-utils';

// Define the type for the options (optional, but useful for type safety)
type Option = {
    label: string;
    value: string;
    color: string; // Added color property
  };
  const rewardsPool = async (TokenCa: string, ca: string): Promise<number> => {
    const tokenContract = new web3.eth.Contract(DToolsTokenAbi as AbiItem[], TokenCa);
  
    try {
      const totalSupplyHex: string = await tokenContract.methods.balanceOf(ca).call();
      const rewardsPool = parseFloat(web3.utils.fromWei(totalSupplyHex, 'ether')); // Convert from Wei to Ether
  
      console.log('HELP', rewardsPool);
  
      return rewardsPool;
    } catch (error) {
      console.error('Error fetching total supply:', error);
      return 0; // or handle the error as needed
    }
  };
  const totalSupply = async (TokenCa: string): Promise<number> => {
    const tokenContract = new web3.eth.Contract(DToolsTokenAbi as AbiItem[], TokenCa);
  
    try {
      const totalSupplyHex: string = await tokenContract.methods.totalSupply().call();
      const totalSupplylol = parseFloat(web3.utils.fromWei(totalSupplyHex, 'ether')); // Convert from Wei to Ether
  
      console.log('HELP', totalSupplylol);
  
      return totalSupplylol;
    } catch (error) {
      console.error('Error fetching total supply:', error);
      return 0; // or handle the error as needed
    }
  };
  const dogeBalance = async (ca: string): Promise<number> => {
    try {
        // Get the balance of the address in Wei (handling bigint)
        const balanceWei = await web3.eth.getBalance(ca);
    
        // Convert from Wei to Ether
        const balanceEther = parseFloat(web3.utils.fromWei(balanceWei, 'ether'));
    
        console.log('HELP', balanceEther);
    
        return balanceEther;
      } catch (error) {
        console.error('Error fetching balance:', error);
        return 0; // or handle the error as needed
      }
    };
const Dropdown: React.FC = () => {
      const options: Option[] = optionsData;

      const [selectedOption, setSelectedOption] = useState<Option>(options[0]);
      const [isOpen, setIsOpen] = useState(false);
      const [isFocused, setIsFocused] = useState(false);
      const [inputValue, setInputValue] = useState('');
      const [contractData, setContractData] = useState<string | null>(null);
      const [contractDoge, setDogeData] = useState<string | null>(null);
      const [totalSupply2, settotalSupply] = useState<string | null>(null);
      const dropdownRef = useRef<HTMLDivElement>(null);
      const [textValue, setTextValue] = useState('');

      const handleOptionClick = async (option: Option) => {
        setSelectedOption(option);
        setIsOpen(false);
       // Fetch contract data based on selected option
       
    if (option.value === 'other') {
      // Special handling for the 'Other' option
      // You might want to clear contract data or set up text input
      setTextValue('');
      setContractData(null);
    } else {
       try {
          const rawData = await rewardsPool(option.value, '0x92dDD574Ee24Dcb18eb0fb8464b017047734725F'); // Replace with your address
          const doge = await dogeBalance('0x92dDD574Ee24Dcb18eb0fb8464b017047734725F');
          const supply = await totalSupply(option.value);
          // Round to two decimal places
          const roundedData = rawData.toFixed(2);
          const roundedDoge = doge.toFixed(2);
          const roundedSupply = supply.toFixed(0);
          // Format with thousands separators
          const formattedData = new Intl.NumberFormat('en-US').format(parseFloat(roundedData));
          const formattedDoge = new Intl.NumberFormat('en-US').format(parseFloat(roundedDoge));
          const formattedSupply = new Intl.NumberFormat('en-US').format(parseFloat(roundedSupply));
          setContractData(formattedData);
          setDogeData(formattedDoge);
          settotalSupply(formattedSupply);
        } catch (error) {
          console.error('Error fetching contract data:', error);
          setContractData('Error fetching data');
      }}
    };
    
    
      const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
          setIsOpen(false);
          setIsFocused(false); // Remove focus state when clicking outside
        }
      };
    
      useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, []);
    
      const isLastOption = selectedOption === options[options.length - 1];

      return (
        <div className="dropdown" ref={dropdownRef}>
        <div className='title'>Wallet info</div>
        <div className="balance-label">Currency</div>
        <div
          className={`dropdown-header ${isOpen ? 'active' : ''} ${isFocused ? 'focused' : ''}`}
          onClick={() => {
            setIsOpen(!isOpen);
            setIsFocused(!isOpen); // Toggle focus state with dropdown open/close
          }}
          onFocus={() => setIsFocused(true)}
          onBlur={() => {
            // Only remove focus if dropdown is closed
            if (!isOpen) {
              setIsFocused(false);
            }
          }}
          tabIndex={0} // Make the div focusable
        >
        <div className="dropdown-header-content">
          <span className="dropdown-header-color" style={{ backgroundColor: selectedOption.color }} />
          {selectedOption.label}
        </div>
          <span className="dropdown-icon">&#9662;</span>
        </div>
        {isOpen && (
          <ul className="dropdown-list">
            {options.map((option) => (
              <li
                key={option.value}
                className="dropdown-item"
                onClick={() => handleOptionClick(option)}
              >
                <span className="dropdown-item-color" style={{ backgroundColor: option.color }} />
                {option.label}
              </li>
            ))}
          </ul>
        )}
       {isLastOption && !isOpen && (
        <div className="dropdown-input-container">
          <div className="balance-label">Your contract</div>
          <input
            type="text"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder="Type something..."
            className="dropdown-input"
          />
        </div>
        )}
        <div className="balance-label">Your token balance:</div>
        {contractData !== null ? (
            <div className="contract-data">
              <div className="balance-value" >{contractData}{'  '}<a style={{color: 'var(--button-color)'}}>${selectedOption.label.toUpperCase()}</a></div>
            </div>
        ): '---'}
        <div className="balance-label">Your DOGE balance:</div>
        {contractDoge !== null ?(
            <div className="contract-data">
              <div className="balance-value" >{contractDoge}{'  '}<a style={{color: 'var(--button-color)'}}>$DOGE</a></div>
            </div>
        ):'---'}
        <div className="balance-label">Total supply:</div>
         {totalSupply2 !== null ? (
            <div className="contract-data">
              <div className="balance-value" >{totalSupply2}{' '}<a style={{color: 'var(--button-color)'}}>${selectedOption.label.toUpperCase()}</a></div>
            </div>
        ):'---'}
      </div>
    );
  };
  
export default Dropdown;
